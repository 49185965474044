<template>
  <div class="container px-0">
    <header>
      <h4 class="text-center my-4">Rolety</h4>
    </header>

    <div class="mb-5 row">
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="wszystkierolety" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="kuchnia" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="salon1" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="salon2" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="salon3" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="lazienka" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="biuro" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="pok1" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="pok2" />
      </div>
      <div class="col-xs-12 col-md-6 col-lg-4">
        <blind-box driver="rolety" func="ustawrolete" param="pok3" />
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import BlindBox from '../components/blinds/BlindBox.vue'

export default {
  name: 'Blinds',
  components: {
    BlindBox
  }
}
</script>
