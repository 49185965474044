var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "box"
  }, [_vm.name ? _c('div', {
    staticClass: "header"
  }, [_vm._v(_vm._s(_vm.name))]) : _vm._e(), _c('div', {
    staticClass: "d-flex"
  }, [_vm._m(0), _c('div', {
    staticClass: "ml-auto mr-2 mt-1"
  }, [_c('FunctionBtn', {
    attrs: {
      "driver": _vm.driver,
      "size": _vm.btnsSize,
      "func": _vm.func,
      "param": _vm.param
    }
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ml-1"
  }, [_c('a', {
    staticClass: "d-inline-block p-2",
    attrs: {
      "href": ""
    }
  }, [_c('i', {
    staticClass: "fas fa-cog"
  })])]);

}]

export { render, staticRenderFns }