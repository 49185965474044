var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container px-0"
  }, [_vm._m(0), _c('div', {
    staticClass: "mb-5 row"
  }, [_c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "wszystkierolety"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "kuchnia"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "salon1"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "salon2"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "salon3"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "lazienka"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "biuro"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "pok1"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "pok2"
    }
  })], 1), _c('div', {
    staticClass: "col-xs-12 col-md-6 col-lg-4"
  }, [_c('blind-box', {
    attrs: {
      "driver": "rolety",
      "func": "ustawrolete",
      "param": "pok3"
    }
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('header', [_c('h4', {
    staticClass: "text-center my-4"
  }, [_vm._v("Rolety")])]);

}]

export { render, staticRenderFns }