<template>
  <div class="box">
    <div class="header" v-if="name">{{ name }}</div>

    <div class="d-flex">
      <div class="ml-1">
        <a href="" class="d-inline-block p-2">
          <i class="fas fa-cog"></i>
        </a>
      </div>

      <div class="ml-auto mr-2 mt-1">
        <FunctionBtn
          :driver="driver"
          :size="btnsSize"
          :func="func"
          :param="param"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BlindBox',
  props: {
    driver: String,
    func: String,
    param: {
      type: [String, Boolean],
      default: false
    }
  },

  data() {
    return {}
  },
  computed: {
    ...mapState(['config', 'driversData']),

    thisObj() {
      return this.config[this.driver].functions[this.func]
    },

    name() {
      if ('params' in this.thisObj) {
        if (typeof this.thisObj.params[this.param] === 'string') {
          return this.thisObj.params[this.param]
        } else {
          return typeof this.thisObj.params[this.param].name
        }
      } else {
        return this.thisObj.name
      }
    },

    btnsSize() {
      return window.innerWidth < 400 ? 'sm' : 'md'
    }
  }
}
</script>

<style lang="scss"></style>
